export const LIST_ROLES = {
    LIST_SUPERVISOR: "list-supervisor",
    LIST_CONTRIBUTOR: "list-contributor",
}

export const SCHOOL_ROLES = {
    SCHOOL_SUPERVISOR: "school-supervisor",
    SCHOOL_CONTRIBUTOR: "school-contributor",
}

export const SYSTEM_ROLES = {
    ADM_SYSTEM: "adm-system",
    SCHOOL_USER: "adm-school",
}

// Peguei essas frases daí: https://www.pensador.com/frases_sobre_educacao/2/
export const EDUCATIONAL_PHRASES = [
    {
        phrase: `“A educação é a arma mais poderosa que você pode usar para mudar o mundo.”`,
        author: `Nelson Mandela`
    },
    {
        phrase: `“O ser humano é aquilo que a educação faz dele.”`,
        author: `Immanuel Kant`
    },
    {
        phrase: `“Educai as crianças e não será preciso punir os homens.”`,
        author: `Pitágoras`
    },
    {
        phrase: `“A educação tem raízes amargas, mas os seus frutos são doces.”`,
        author: `Aristóteles`
    },
    {
        phrase: `“Só a educação liberta.”`,
        author: `Epicteto`
    },
    {
        phrase: `“Educar a mente sem educar o coração não é educação.”`,
        author: `Aristóteles`
    },
    {
        phrase: `“Educar é mostrar a vida a quem ainda não a viu.”`,
        author: `Rubem Alves`
    },
    {
        phrase: `“A vida é uma grande universidade, mas pouco ensina a quem não sabe ser um aluno...”`,
        author: `Augusto Cury`
    },
    {
        phrase: `“É na educação dos filhos que se revelam as virtudes dos pais.”`,
        author: `Coelho Neto`
    },
    {
        phrase: `“É no problema da educação que assenta o grande segredo do aperfeiçoamento da humanidade.”`,
        author: `Immanuel Kant`
    },
    {
        phrase: `“A boa educação é moeda de ouro. Em toda a parte tem valor.”`,
        author: `Padre Antônio Vieira`
    },
    {
        phrase: `“Se você acha que educação é cara, experimente a ignorância.”`,
        author: `Robert Orben`
    },
    {
        phrase: `“Feliz aquele que transfere o que sabe e aprende o que ensina.”`,
        author: `Cora Coralina`
    },
    {
        phrase: `“Educar é semear com sabedoria e colher com paciência.”`,
        author: `Augusto Cury`
    },
]



export const INVITATION_STATUS = {
    UNUSED: 'unused',   // quando um email recebeu um novo convite, os anteriores são setados como UNUSED
    PENDING: 'pending', // quando um email recebe um convite
    USED: 'used',       // quando o usuário realiza o cadastro com o convite
    EXPIRED: 'expired'  // *NÃO UTILIZADO AINDA* deveria ser usado quando o tempo expira, mas talvez não seja necessário
}

export const RECORD_TYPE = {
    CREATE: 'CREATE',
    DELETE: 'DELETE', 
    APPROVE: 'APPROVE',
    UNAPPROVE: 'UNAPPROVE',
    UPDATE: 'UPDATE',
}

export const RECORD_ENTITY = {
    LIST: 'LIST',
    ITEM: 'LIST_ITEM',
    SCHOOL: 'SCHOOL'
}