import axios from "axios"
import {doLogout, getAccessToken} from "./userService";


const getInstance = () => {
    const token = getAccessToken()
    const headers = {
        Authorization: "Bearer " + token,
    }

    const _axios = axios.create({
        headers: headers,
    })

    _axios.interceptors.response.use(function (response) {
        return response
    }, error => {
        if (error.response.status === 401) {
            doLogout()
            return {}
        }
        throw error
    })

    return _axios
}

const api = {}

api.post = (uri, data, params) => getInstance().post(uri, data, params)

api.get = (uri, params) => getInstance().get(uri, params)

api.delete = (uri) => getInstance().delete(uri)

api.put = (uri, data) => getInstance().put(uri, data)

export default api