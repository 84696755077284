import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import posthog from 'posthog-js'
posthog.init('phc_drxMn1viKaMOhsC14UFtUJxbWt6eivJF8uBOuyhqA0x', { api_host: 'https://app.posthog.com', autocapture: false, captureClicks: false, capture_pageleave: false, capture_pageview: false })

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const currentUrl = window.location.href;
  const urlObject = new URL(currentUrl);
  const path = urlObject.pathname;
  if(path !== '/'){
    posthog.capture('UrlPageVisited', {UrlPage: currentUrl})
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {routing}
        </RTL>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
