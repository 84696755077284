const typography = {
  fontFamily: "'DM Sans', sans-serif",
  text2: {
    color: "#757575",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px"
  },
  text6: {
    color: "#777E89",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px"
  },
  text3: {
    color: "#777E89",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%"
  },
  text5: {
    color: "#11142D",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%"
  },
  text1: {
    color: "#11142D",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "150%"
  },
  text4: {
    color: "#11142D",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "150%"
  },


  body1: {
    fontWeight: 400, // or 'bold'
  },
  h1: {
    fontWeight: 500,
    fontSize: '1.875rem',
    lineHeight: '1.5',
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '1.5',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.3125rem',
    lineHeight: '1.5',
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  button: {
    textTransform: 'none',
    fontWeight: '400',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: '400',
  },
  itemTitleTypography: {
    color: "text.primaryLight", 
    fontSize: "24px"
  }, 
  itemTitleDescriptionTypography: {
    color: "text.secondaryLight",
    fontSize: "16px"
  }, 
  itemTypography: {
    color: "text.primaryLight2",
  },
  itemDescriptionTypography: {
    color: "secondaryGray.sg400",
    fontSize: "12px"
  }, 
  titleSharingListTypography: {
    color: "text.primaryLight",
    fontSize: "28px"
  }, 
  titleDescriptionSharingListTypography: {
    color: "text.primaryLight",
    fontSize: "18px"
  },
  title1: {
      fontWeight: 700,
      fontSize: 30,
  },
  title2: {
      fontWeight: 500,
      fontSize: 20,
  },
  title3: {
    fontWeight: 400,
    fontSize: 16,
  },
  title4: {
    fontWeight: 400,
    fontSize: 28,
  },
  title5: {
    fontWeight: 400,
    fontSize: 14,
  },
  title6: {
    fontWeight: 500,
    fontSize: 16,
  }
};

export default typography;
