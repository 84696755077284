import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator, documentId } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, connectStorageEmulator } from "firebase/storage";

import {
  getAuth,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  browserLocalPersistence,
  setPersistence,
  updatePassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth'
import {
  collection,
  doc,
  setDoc,
  getCountFromServer,
  query,
  where,
  getDocs,
  onSnapshot,
  deleteDoc,
  orderBy,
  limit,
  getDoc,
} from 'firebase/firestore'

const devConfig = {
  useEmulator: false,
  functionsEndpoint: "https://us-central1-dev-scolados.cloudfunctions.net/app",
  apiKey: "AIzaSyDOH0b7d8Fyvvu8GuoB_9wVoE17vXJyDgc",
  authDomain: "dev-scolados.firebaseapp.com",
  projectId: "dev-scolados",
  storageBucket: "dev-scolados.appspot.com",
  messagingSenderId: 579428459627,
  appId: "1:579428459627:web:f1d8b8c6d0f56c80ca7d2e",
  ignoreUndefinedProperties: true,
}

const prodConfig = {
  useEmulator: false,
  functionsEndpoint: "https://us-central1-prod-scolados.cloudfunctions.net/app",
  apiKey: "AIzaSyCnvIV5RtZ7ecIB4Ct3wtQ2WK09kO4alcE",
  authDomain: "prod-scolados.firebaseapp.com",
  projectId: "prod-scolados",
  storageBucket: "prod-scolados.appspot.com",
  messagingSenderId: 51420039550,
  appId: "1:1:51420039550:web:c60a7b17e26440abb00b2f",
  ignoreUndefinedProperties: true,
}

const firebaseConfig = {
  useEmulator: true,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  ignoreUndefinedProperties: true,
}

let currentAppConfig = null
let functionsEndpoint = null
let storageEndpoint = null
const currentAppHost = window.location.hostname
console.log(currentAppHost)

if (process.env.REACT_APP_USER_EMULATORS === 'true' || currentAppHost === 'localhost') {
  currentAppConfig = firebaseConfig
  functionsEndpoint = 'http://localhost:5001/dev-scolados/us-central1/app'
  storageEndpoint = 'http://localhost:9199/v0/b/dev-scolados.appspot.com/o'
  console.log("USING LOCALHOST CONFIG")
} else if (currentAppHost.includes('dev-scolados')) {
  currentAppConfig = devConfig
  functionsEndpoint = 'https://us-central1-dev-scolados.cloudfunctions.net/app'
  storageEndpoint = 'https://firebasestorage.googleapis.com/v0/b/dev-scolados.appspot.com/o'
  console.log("USING DEV CONFIG")
} else if (currentAppHost.includes('prod-scolados') || currentAppHost ==="listaescolar.scolados.com") {
  currentAppConfig = prodConfig
  functionsEndpoint = 'https://us-central1-prod-scolados.cloudfunctions.net/app'
  storageEndpoint = 'https://firebasestorage.googleapis.com/v0/b/prod-scolados.appspot.com/o'
  console.log("USING PROD CONFIG")
}

// Initialize Firebase
const app = initializeApp(currentAppConfig)
const storage = getStorage(app)
const db = getFirestore()
const auth = getAuth()

if (currentAppConfig.useEmulator) {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080),
  connectStorageEmulator(storage, 'localhost', 9199)
}

export {
  db,
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  onSnapshot,
  getCountFromServer,
  auth,
  orderBy,
  deleteDoc,
  limit,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  browserLocalPersistence,
  setPersistence,
  updatePassword,
  storage,
  functionsEndpoint,
  ref,
  getDownloadURL,
  documentId,
  storageEndpoint,
  GoogleAuthProvider,
  signInWithPopup
}
