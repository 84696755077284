import _ from 'lodash';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import typography from './Typography';
import components from './Override';
import shadows from './Shadows';

// ##############################import {
import {
  BLUE_THEME,
  GREEN_THEME,
  RED_THEME,
  BLACK_THEME,
  PURPLE_THEME,
  INDIGO_THEME,
  ORANGE_THEME, SCOLADOS_THEME,
} from '../../redux/constants';
// // // Global Variables
// ##############################

const SidebarWidth = 265;
const TopbarHeight = 70;

const baseTheme = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#2F97A1',
      p900: '#10565d',
      p800: '#1e767f',
      p700: '#2f97a1',
      p600: '#44b8c3',
      p500: '#5dd9e5',
      p400: '#77f3ff',
      p300: '#95f5ff',
      p200: '#b4f8ff',
      p100: '#d2fbff',
      p50: '#f1feff',
      perfil: '#1AD18F',
      icon: '#39CB7F',
    },
    secondary: {
      main: '#11142D',
      t900: '#23284f',
      t800: '#3b4071',
      t700: '#575d93',
      t600: '#777eb5',
      t500: '#9da3d7',
      t400: '#c7cdf9',
      t300: '#d7dcff',
      t200: '#e3e6ff',
      t100: '#eef0ff',
      t50: '#fafaff',
    },
    tertiary: {
      main: '#FCB316',
      t900: '#593d00',
      t800: '#825900',
      t700: '#aa7400',
      t600: '#d39103',
      t500: '#fcb316',
      t400: '#ffc240',
      t300: '#ffd06a',
      t200: '#ffdd94',
      t100: '#ffeabe',
      t50: '#fff7e7',
    },
    warning: {
      main: '#FB9678',
      w900: '#4c2317',
      w800: '#693323',
      w700: '#864430',
      w600: '#a4563f',
      w500: '#c16a51',
      w400: '#de7f63',
      w300: '#fb9678',
      w200: '#ffb7a2',
      w100: '#ffd6ca',
      w50: '#fff4f1',
    },
    critical: {
      main: '#FC4B6C',
      c900: '#520513',
      c800: '#740d20',
      c700: '#96172f',
      c600: '#b82541',
      c500: '#da3755',
      c400: '#fc4b6c',
      c300: '#ff748e',
      c200: '#ff9caf',
      c100: '#ffc5cf',
      c50: '#ffedf0',
    },
    success: {
      main: '#5BD495',
      sc900: '#114228',
      sc800: '#1b5f3c',
      sc700: '#287d51',
      sc600: '#379a66',
      sc500: '#48b77d',
      sc400: '#5bd495',
      sc300: '#70f1ae',
      sc200: '#9ffecc',
      sc100: '#c8ffe2',
      sc50: '#f1fff8',
    },
    primaryGray: {
      main: '#252525',
      pg900: '#3f4040',
      pg800: '#545454',
      pg700: '#696969',
      pg600: '#7d7d7d',
      pg500: '#929292',
      pg400: '#a6a6a6',
      pg300: '#bababa',
      pg200: '#cfcfcf',
      pg100: '#e3e3e3',
      pg50: '#f8f8f8',
    },
    secondaryGray: {
      main: '#949DB2',
      sg900: '#303541',
      sg800: '#424957',
      sg700: '#565d6e',
      sg600: '#6a7285',
      sg500: '#7e879b',
      sg400: '#949db2',
      sg300: '#aab3c9',
      sg200: '#c2cbdf',
      sg100: '#dae2f6',
      sg50: '#f8f9fb',
    },
    danger: {
      main: '#e46a76',
      light: '#fdf3f5',
    },
    info: {
      main: '#0bb2fb',
      light: '#F1F9FF',
    },
    error: {
      main: '#e46a76',
      light: '#fdf3f5',
      dark: '#e45a68',
      error70: '#FF897D',
      error95: '#FFEDEA',
    },
    text: {
      main: '#2F97A1',
      primary: '#11142D',
      primaryLight: '#6D6E71',
      primaryLight2: '#757575',
      primaryLight3: '#3F4040',
      secondary: '#777E89',
      secondaryLight: '#C1C1C1',
      danger: '#fc4b6c',
      link: '#298D8D',
    },
    grey: {
      A100: '#ecf0f2',
      A200: '#99abb4',
      A400: '#767e89',
      A700: '#e6f4ff',
    },
    action: {
      disabledBackground: 'rgba(73,82,88,0.12)',
      hoverOpacity: 0.02,
      hover: 'rgba(0, 0, 0, 0.03)',
    },
  },
  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      color: '#949db2',
      '@media(min-width:1280px)': {
        minHeight: TopbarHeight,
        padding: '0 30px',
      },
      '@media(max-width:1280px)': {
        minHeight: '64px',
      },
    },
  },
  status: {
    danger: '#e53e3e',
  },
  components,
  typography,
  shadows,
};

export const BuildTheme = (config = {}) => {

  const theme = createTheme(
    _.merge({}, baseTheme, {
      direction: config.direction,
    }),
  );
  return theme;
};

export { TopbarHeight, SidebarWidth, baseTheme };
