const components = {
  MuiCssBaseline: {
    styleOverrides: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        margin: 0,
        padding: 0,
      },
      '#root': {
        height: '100%',
      },
      "*[dir='rtl'] .buyNowImg": {
        transform: 'scaleX(-1)',
      },

      '.buyNowImg': {
        position: 'absolute',
        right: '-44px',
        top: '-18px',
        width: '143px',
        height: '175px',
      },
      '.MuiCardHeader-action': {
        alignSelf: 'center !important',
      },
      '.scrollbar-container': {
        borderRight: '0 !important',
      },
      "*[dir='rtl'] .welcomebg:before": {
        backgroundPosition: 'top -24px left -9px !important',
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        maxWidth: '1600px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        boxShadow: 'none',
        fontSize: '15px',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    variants: [
      {
        props: { variant: 'style1' },
        style: {
          borderRadius: 28,
          padding: "12px 20px",
          textAlign: "center",
          border: "1px solid rgba(47, 151, 161, 0.50);"
        },
      },
      {
        props: { variant: 'style2' },
        style: {
          borderRadius: 28,
          padding: "12px 20px",
          textAlign: "center",
          color: "#2F97A1",
          backgroundColor: "#EBF8F8",
          border: "none"
        },
      },
      {
        props: { variant: 'style3' },
        style: {
          borderRadius: "5px",
          padding: "12px 10px",
          backgroundColor: "#F1FEFF",
          color: "#44B8C3",
        }
      },
      {
        props: { variant: 'style4' },
        style: {
          borderRadius: "100px",
          padding: "8px 16px",
          backgroundColor: "#D2FBFF",
          color: "#2F97A1",
          height: "34px",
          fontSize: "13px"
        }
      },
      {
        props: { variant: 'style5' },
        style: {
          borderRadius: "5px",
          padding: "8px 16px",
          backgroundColor: "trasparent",
          color: "#FB9678",
          border: "1px solid #FB9678",
        }
      }
    ],
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: '9px',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: '20px',
        padding: '14px',
        margin: '15px',
        boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
      },
    },
    variants: [
      {
        props: { variant: 'lined' },
        style: {
          borderRadius: '20px',
          padding: '14px',
          margin: '15px',
          border: '1px solid #DAE2F6',
        },
      }, 
      {
        props: { variant: 'titleSharingListCard'}, 
        style: {
          padding: "30px 70px",
          margin: 0,
          marginTop: "36px" 
        },
      }, 
      {
        props: { variant: 'itemSharingListCard'}, 
        style: {
          margin: 0,
          padding: "30px 70px",
          marginTop: "20px"
        },
      },
    ],
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: '40px',
      },
    },
  },
  MuiGridItem: {
    styleOverrides: {
      root: {
        paddingTop: '30px',
        paddingLeft: '30px !important',
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: '#ecf0f2',
        borderRadius: '6px',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: '0',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: '500',
        fontSize: '0.75rem',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
         color: '#fff'
      },
      icon: {
        color: '#fff !important',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
         width: "100%"
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        borderRadius: '5px',
        border: '1px solid #AEB5B5',
        color: '#11142D',
        fontSize: '14px',
        padding: '5px 10px',
        '&::before': {
          borderBottom: 'none !important',
        },
        '&::after': {
          borderBottom: 'none !important',
        },
      },
      input: {
        padding: '8px 14px  !important',
      }
    }
  },
  MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        }
      }
  },
};

export default components;
